import React from 'react';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import { useIntl } from '@atlassian/jira-intl';
import { useAllUserInformationOnIssues } from '../../../../../../controllers/issue/selectors/users-hooks';
import messages from './messages';

type Props = {
	groupIdentity: string | undefined;
};

export const userNoValueOptionMessage = messages.noValueOption;

export const UserOptionRenderer = ({ groupIdentity }: Props) => {
	const userInfos = useAllUserInformationOnIssues();
	const { formatMessage } = useIntl();

	if (groupIdentity === undefined) {
		return (
			<AvatarItem
				avatar={<Avatar appearance="circle" size="xsmall" />}
				primaryText={formatMessage(userNoValueOptionMessage)}
			/>
		);
	}

	const userInfo = userInfos[groupIdentity];
	if (userInfo === undefined) {
		return (
			<AvatarItem
				avatar={
					<Avatar
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="polaris-ideas.ui.view-controls.config-filters.filter-component.options.renderers.user.avatar"
						appearance="circle"
						size="xsmall"
					/>
				}
				primaryText={formatMessage(messages.unknownUser)}
			/>
		);
	}

	return (
		<AvatarItem
			avatar={
				<Avatar
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					testId="polaris-ideas.ui.view-controls.config-filters.filter-component.options.renderers.user.avatar"
					appearance="circle"
					src={userInfo.avatarUrls?.['16x16']}
					size="xsmall"
				/>
			}
			primaryText={userInfo.displayName}
		/>
	);
};
