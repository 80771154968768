import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useGetFilterTypeForField,
	useIsInFilterResult,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks';
import {
	useIntervalFilter,
	useFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { START_BEFORE_NOW } from '@atlassian/jira-polaris-domain-view/src/filter/types';
import { isIntervalFieldFilterLegacy } from '@atlassian/jira-polaris-domain-view/src/filter/utils';
import { Number } from '../number';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	isTemporary?: boolean;
};

export const NumDataPoints = ({ fieldKey, localIssueId, isTemporary }: Props) => {
	const { formatMessage } = useIntl();
	const filterType = useGetFilterTypeForField(fieldKey);
	const filter = useFilter({ fieldKey, filterType, isTemporary });
	const isInFilterResult = useIsInFilterResult(fieldKey, filter, localIssueId);
	const intervalFilter = useIntervalFilter(fieldKey, { isTemporary });

	if (
		!isInFilterResult &&
		isIntervalFieldFilterLegacy(intervalFilter) &&
		intervalFilter.values.length
	) {
		switch (intervalFilter.values[0]?.operator) {
			case START_BEFORE_NOW:
				return (
					<>
						{formatMessage(messages.noCreatedInsightsHint, {
							count: intervalFilter.values[0]?.numericValue,
						})}
					</>
				);
			default:
		}
	}

	return <Number fieldKey={fieldKey} localIssueId={localIssueId} />;
};
