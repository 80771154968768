import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import { Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenIdeaViewFieldSidebar } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view';
import {
	useGetFilterTypeForField,
	useIsInFilterResult,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/filters-hooks';
import {
	useActivePermanentFiltersFields,
	useIsQuickSearchFilterDefined,
	useFilter,
	useActiveTemporaryFiltersFields,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import { useCanManagePermanentFilters } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { FilterComponent } from '@atlassian/jira-polaris-common/src/ui/filters/filter-component';
import { ISSUEID_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { Y300, G300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { FieldComponent } from './field';
import messages from './messages';
import { SummaryFilterComponent } from './summary-filter';

type FilterStatusProps = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	isTemporary?: boolean;
};

const FilterStatus = ({ fieldKey, localIssueId, isTemporary }: FilterStatusProps) => {
	const filterType = useGetFilterTypeForField(fieldKey);
	const filter = useFilter({ fieldKey, filterType, isTemporary });
	const isInFilterResult = useIsInFilterResult(fieldKey, filter, localIssueId);

	if (isInFilterResult) {
		return (
			<Box>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				<EditorSuccessIcon size="medium" label="ok" primaryColor={G300} />
			</Box>
		);
	}

	return (
		<Box>
			{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
			<EditorWarningIcon size="medium" label="filtered out" primaryColor={Y300} />
		</Box>
	);
};

type Props = {
	localIssueId: LocalIssueId;
	portalElement?: HTMLElement;
	onClose: () => void;
};

export const ActiveFilters = ({ localIssueId, portalElement, onClose }: Props) => {
	const { formatMessage } = useIntl();

	const canManagePermanentFilters = useCanManagePermanentFilters();

	const activePermanentFiltersFields = useActivePermanentFiltersFields();
	const activeTemporaryFiltersFields = useActiveTemporaryFiltersFields();
	const isQuickSearchFilterDefined = useIsQuickSearchFilterDefined();

	const activeFiltersFields: (Field & { hasTemporaryFilter?: boolean })[] = useMemo(
		() => [
			...activePermanentFiltersFields,
			...activeTemporaryFiltersFields.map((field) => ({ ...field, hasTemporaryFilter: true })),
		],
		[activePermanentFiltersFields, activeTemporaryFiltersFields],
	);

	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const onManageField = useCallback(
		(fieldKey: string, optionId?: string) => {
			onClose();
			openIdeaViewFieldSidebar(fieldKey, optionId);
		},
		[openIdeaViewFieldSidebar, onClose],
	);

	const rowOffset = isQuickSearchFilterDefined ? 3 : 2;

	return (
		<Container>
			<FilterHeader>{formatMessage(messages.filterHeading)}</FilterHeader>
			<ValueHeader>{formatMessage(messages.valueHeading)}</ValueHeader>
			{isQuickSearchFilterDefined && (
				<>
					<FilterWrapper row={2}>
						<SummaryFilterComponent />
					</FilterWrapper>
					<StatusWrapper row={2}>
						<FilterStatus fieldKey="summary" localIssueId={localIssueId} />
					</StatusWrapper>
					<ValueWrapper row={2}>
						<FieldComponent
							fieldKey="summary"
							localIssueId={localIssueId}
							onManageField={onManageField}
							portalElement={portalElement}
						/>
					</ValueWrapper>
				</>
			)}
			{activeFiltersFields.map((field, idx, { length }) => {
				// do not show issue-id filter
				if (field.key === ISSUEID_FIELDKEY) {
					return null;
				}

				return (
					<React.Fragment key={field.key}>
						<FilterWrapper row={idx + rowOffset}>
							<FilterComponent
								field={field}
								isLastFilter={length - 1 === idx}
								isTemporary={field.hasTemporaryFilter}
								isDisabled={!canManagePermanentFilters && !field.hasTemporaryFilter}
							/>
						</FilterWrapper>
						<StatusWrapper row={idx + rowOffset}>
							<FilterStatus
								fieldKey={field.key}
								localIssueId={localIssueId}
								isTemporary={field.hasTemporaryFilter}
							/>
						</StatusWrapper>
						<ValueWrapper row={idx + rowOffset}>
							<FieldComponent
								fieldKey={field.key}
								localIssueId={localIssueId}
								onManageField={onManageField}
								portalElement={portalElement}
								isTemporary={field.hasTemporaryFilter}
							/>
						</ValueWrapper>
					</React.Fragment>
				);
			})}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'grid',
	gridTemplateColumns: '3fr 48px 2fr',
	gridGap: '4px',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterWrapper = styled.div<{ row: any }>({
	gridColumn: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridRow: ({ row }) => row,
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterHeader = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	textTransform: 'uppercase',
	gridColumn: 1,
	gridRow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueHeader = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	textTransform: 'uppercase',
	gridColumn: 3,
	gridRow: 1,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueWrapper = styled.div<{ row: any }>({
	gridColumn: 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridRow: ({ row }) => row,
	alignSelf: 'center',
	maxWidth: '350px',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusWrapper = styled.div<{ row: any }>({
	gridColumn: 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridRow: ({ row }) => row,
	alignSelf: 'center',
	justifySelf: 'end',
});
