import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldMapping } from '../../../../../../controllers/issue/selectors/fields-hooks';
import messages from './messages';

type Props = {
	field: Field;
	groupIdentity?: string;
	value: unknown;
};

export const defaultNoValueOptionMessage = messages.noValueOption;

export const DefaultOptionRenderer = ({ field, groupIdentity, value }: Props) => {
	const mapping = useFieldMapping(field.key);
	const { formatMessage } = useIntl();

	if (groupIdentity === undefined) {
		return <Box>{formatMessage(defaultNoValueOptionMessage)}</Box>;
	}

	return <>{mapping?.getLabel(groupIdentity, value) || formatMessage(messages.unknownOption)}</>;
};
