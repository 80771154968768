import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { ProjectField } from '../../../../../fields/project';
import messages from './messages';

type Props = {
	groupIdentity: string | undefined;
};

export const optionNoValueOptionMessage = messages.noValueOption;

export const ProjectOptionRenderer = ({ groupIdentity }: Props) => {
	const projectMeta = useProjectMetadata({ projectId: groupIdentity });
	const { formatMessage } = useIntl();

	if (groupIdentity === undefined) {
		return <Box paddingInlineStart="space.100">{formatMessage(optionNoValueOptionMessage)}</Box>;
	}

	return <ProjectField project={projectMeta} />;
};
