import type { MutableRefObject } from 'react';
import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueRefreshLoadingActions } from '@atlassian/jira-issue-field-base/src/services/issue-refresh-loading-service/index.tsx';
import type { ContainersByType } from '@atlassian/jira-issue-layout-common-constants';
import type { IssueViewNonCriticalDataActions } from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-issue-view-non-critical-data/types.tsx';
import {
	type IssueRefreshServiceActions,
	Reason,
} from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import type { ProjectContext } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import type { UserPreferenceActions } from '@atlassian/jira-issue-user-preference-services/src/types.tsx';
import { REALTIME_EVENTS_ANALYTICS_SAMPLE_RATE } from '@atlassian/jira-issue-view-common-constants/src/realtime-support.tsx';
import { fetchViewContextWithErrorHandling } from '@atlassian/jira-issue-view-common-media/src/view-context/view-context-epic';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { storeFields } from '@atlassian/jira-issue-view-common-utils/src/utils/fields-extraction';
import { getAssociatedIssuesContext } from '@atlassian/jira-issue-view-common-utils/src/utils/get-associated-issues-context';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
	DATA_CLASSIFICATION_FIELD,
} from '@atlassian/jira-issue-view-configurations';
import type {
	EcosystemActions,
	State as EcosystemState,
} from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import type { IssueViewLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/types.tsx';
import { fetchAllAppDataWithRetries } from '@atlassian/jira-issue-view-services/src/issue/issue-fetch-server';
import {
	type RefreshIssueRequestAction,
	refreshIssueSuccess,
	REFRESH_ISSUE_REQUEST,
	CANCEL_REFRESH_ISSUE_REQUEST,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { isServiceDeskSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ProjectContextServiceActions } from '@atlassian/jira-project-context-service/src/types.tsx';
import type { ProjectPermissionActions } from '@atlassian/jira-project-permissions-service/src/types.tsx';
import { toProjectKey, toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { throttleIssueRefresh } from './constants';

const LOG_LOCATION = 'issue.fetch.issue-refresh-epic';

const issueRefreshEpic =
	({
		issueRefreshServiceActions,
		fieldValueActions,
		fieldConfigActions,
		issueViewLayoutActions,
		userPreferenceActions,
		attachmentActions,
		permissionActions,
		projectContextActions,
		issueContextActions,
		ecosystemActions,
		associatedIssuesContextActions,
		prefetchedResourceManager,
		issueRefreshLoadingActionsRef,
		issueViewNonCriticalDataActions,
	}: {
		issueRefreshServiceActions: IssueRefreshServiceActions;
		fieldValueActions: FieldValueServiceActions;
		fieldConfigActions: FieldConfigServiceActions;
		issueViewLayoutActions: IssueViewLayoutActions;
		userPreferenceActions: UserPreferenceActions;
		permissionActions: ProjectPermissionActions;
		attachmentActions: AttachmentServiceActions;
		projectContextActions: ProjectContextServiceActions;
		issueContextActions: IssueContextServiceActions;
		ecosystemActions: EcosystemActions;
		associatedIssuesContextActions?: AssociatedIssuesContextActions;
		prefetchedResourceManager?: ResourceManager;
		issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>;
		issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions;
	}) =>
	(action$: ActionsObservable<RefreshIssueRequestAction>, store: MiddlewareAPI<State>) => {
		const observable$ = action$.ofType(REFRESH_ISSUE_REQUEST);
		return observable$.pipe(
			throttleIssueRefresh(),
			switchMap(({ payload, meta }) => {
				const state = store.getState();
				const baseUrl = baseUrlSelector(state);
				const issueKey = issueKeySelector(state);
				const maxTokenLength = issueContextActions.getMaxTokenLength();
				const isServiceDesk = isServiceDeskSelector(state);
				const issueRefreshLoadingActions = issueRefreshLoadingActionsRef.current;

				if (payload?.reason === Reason.Realtime) {
					issueRefreshLoadingActions?.setRealTimeRefreshLoadingStatus('LOADING');
				} else {
					issueRefreshLoadingActions?.setBackgroundRefreshLoadingStatus('LOADING');
				}

				const fetchAllAppDataWithRetries$ = fetchAllAppDataWithRetries(
					state,
					prefetchedResourceManager,
				);

				const fetchAllAppDataWithRetriesCancellable$ = fetchAllAppDataWithRetries$.takeUntil(
					action$.ofType(CANCEL_REFRESH_ISSUE_REQUEST),
				);

				ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w') &&
					issueViewNonCriticalDataActions?.fetchNonCriticalData(issueKey);

				return Observable.zip(
					fetchViewContextWithErrorHandling(baseUrl, issueKey, maxTokenLength, isServiceDesk),
					fetchAllAppDataWithRetriesCancellable$
						.do(
							({
								childIssuesLimit,
								issue: { fields, id, viewScreenId },
								myPreferences,
								attachments,
								permissions,
								containersByType,
								project,
								isArchived,
								ecosystem,
								childrenIssues,
								subtasks,
								issueLinks,
							}) => {
								storeFields(fields, fieldValueActions, fieldConfigActions, issueKey, project, true);

								issueViewLayoutActions.setIssueViewContainersLayout(
									issueKey,
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									containersByType as ContainersByType,
								);

								userPreferenceActions.setUserPreferences(myPreferences);

								ecosystemActions &&
									ecosystemActions.setEcosystem(
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										ecosystem as EcosystemState,
										issueKey,
									);

								permissions &&
									project &&
									permissionActions.setProjectPermissions(
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										toProjectKey(project.projectKey as string),
										permissions,
									);

								attachments && attachmentActions.setTotalCount(issueKey, attachments.totalCount);
								attachments &&
									typeof attachments.deletableCount === 'number' &&
									attachmentActions.setDeletableCount(issueKey, attachments.deletableCount);
								attachments && attachmentActions.setVisibleAttachments(issueKey, attachments.nodes);

								project &&
									projectContextActions.setProjectContext(
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										toProjectKey(project.projectKey as string),
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										project as ProjectContext,
									);

								id &&
									issueContextActions.mergeIssueContext({
										issueId: toIssueId(String(id)),
									});

								issueContextActions.mergeIssueContext({ isArchived });

								if (associatedIssuesContextActions) {
									associatedIssuesContextActions.mergeLocalAssociatedIssuesContext(
										getAssociatedIssuesContext([
											...childrenIssues,
											...subtasks,
											...(issueLinks?.linkedIssueMetas || []),
										]),
									);
								}
								const dataClassifcationFieldValue = fields[DATA_CLASSIFICATION_FIELD]?.value || {};
								const isClassifyEditEnabled =
									!!dataClassifcationFieldValue?.fieldConfig?.isEditable;

								issueContextActions.mergeIssueContext({
									childIssuesLimit,
									childIssuesIssueLimitUrls: {
										[SUBTASKS]: fields[SUBTASKS]?.issueLimitUrl,
										[CHILDREN_ISSUES]: fields[CHILDREN_ISSUES]?.issueLimitUrl,
										[EPIC_ISSUES]: fields[EPIC_ISSUES]?.issueLimitUrl,
										[PORTFOLIO_CHILD_ISSUES]: fields[PORTFOLIO_CHILD_ISSUES]?.issueLimitUrl,
									},
									viewScreenId,
									isClassifyEditEnabled,
								});

								if (payload?.reason === Reason.Realtime) {
									const { analyticsEvent } = meta || {};
									analyticsEvent &&
										isInSample(REALTIME_EVENTS_ANALYTICS_SAMPLE_RATE) &&
										fireOperationalAnalytics(analyticsEvent, 'issueRealtimeEvent handled', {
											issueRefreshed: true,
										});

									issueRefreshServiceActions.setEvent({
										refreshed: true,
										reason: payload.reason,
										details: payload.details,
									});
									issueRefreshLoadingActions?.setRealTimeRefreshLoadingStatus('LOADED');
								} else if (payload?.reason === Reason.SoftRefresh) {
									issueRefreshServiceActions.setEvent({
										refreshed: true,
										reason: payload.reason,
									});
									issueRefreshLoadingActions?.setBackgroundRefreshLoadingStatus('LOADED');
								} else {
									issueRefreshServiceActions.setEvent({
										refreshed: true,
										reason: Reason.OnDemand,
									});
									issueRefreshLoadingActions?.setBackgroundRefreshLoadingStatus('LOADED');
								}
							},
						)
						.map(refreshIssueSuccess),
				)
					.mergeMap((actions) => Observable.from(actions))
					.catch((error) => {
						log.safeErrorWithoutCustomerData(
							LOG_LOCATION,
							'REFRESH_ISSUE_REQUEST: Failed to refresh issue data',
							error,
						);
						return Observable.empty<never>();
					});
			}),
		);
	};

export default issueRefreshEpic;
