import React from 'react';
import {
	useIssueKey,
	useSummary,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { ShortLabel } from '@atlassian/jira-polaris-common/src/ui/issue-short-label';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type Props = {
	issueId: LocalIssueId;
};

export const Selectable = ({ issueId }: Props) => {
	const summary = useSummary(issueId);
	const issueKey = useIssueKey(issueId);

	return <ShortLabel issueKey={issueKey} summary={summary} />;
};
