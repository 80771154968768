import type { useIntl } from '@atlassian/jira-intl';
import {
	INTERVAL_FILTER_DATE_TYPE_END,
	INTERVAL_FILTER_DATE_TYPE_START,
	INTERVAL_FILTER_PERIOD_DAY,
	INTERVAL_FILTER_PERIOD_MONTH,
	INTERVAL_FILTER_PERIOD_WEEK,
	INTERVAL_FILTER_PERIOD_YEAR,
	INTERVAL_FILTER_TIME_CURRENT,
	INTERVAL_FILTER_TIME_NEXT,
	INTERVAL_FILTER_TIME_PAST,
	type IntervalFilterDateType,
	type IntervalFilterPeriod,
	type IntervalFilterTime,
} from '@atlassian/jira-polaris-domain-view/src/filter/types';
import { assertUnreachable } from '../utils/types';
import { messages } from './messages';

type FormatMessage = ReturnType<typeof useIntl>['formatMessage'];

export const formatMessageIntervalFilterDateType = (
	formatMessage: FormatMessage,
	intervalDateType: IntervalFilterDateType,
): string => {
	switch (intervalDateType) {
		case INTERVAL_FILTER_DATE_TYPE_START:
			return formatMessage(messages.intervalDateTypeStartDate);
		case INTERVAL_FILTER_DATE_TYPE_END:
			return formatMessage(messages.intervalDateTypeEndDate);
		default:
			assertUnreachable(intervalDateType);
	}
};

export const formatMessageIntervalFilterTime = (
	formatMessage: FormatMessage,
	time: IntervalFilterTime,
): string => {
	switch (time) {
		case INTERVAL_FILTER_TIME_NEXT:
			return formatMessage(messages.timeNext);
		case INTERVAL_FILTER_TIME_PAST:
			return formatMessage(messages.timePast);
		case INTERVAL_FILTER_TIME_CURRENT:
			return formatMessage(messages.timeCurrent);
		default:
			assertUnreachable(time);
	}
};

export const formatMessageIntervalFilterPeriod = (
	formatMessage: FormatMessage,
	period: IntervalFilterPeriod,
	count: number,
): string => {
	switch (period) {
		case INTERVAL_FILTER_PERIOD_DAY:
			return formatMessage(messages.periodDay, { count });
		case INTERVAL_FILTER_PERIOD_MONTH:
			return formatMessage(messages.periodMonth, { count });
		case INTERVAL_FILTER_PERIOD_WEEK:
			return formatMessage(messages.periodWeek, { count });
		case INTERVAL_FILTER_PERIOD_YEAR:
			return formatMessage(messages.periodYear, { count });
		default:
			assertUnreachable(period);
	}
};
