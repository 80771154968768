import React from 'react';
import type { BasicIssue } from '../../../../../../controllers/issue/types';
import { ShortLabel } from '../../../../../issue-short-label';

type Props = {
	basicIssue: BasicIssue;
};

export const BasicIssueOptionRenderer = ({ basicIssue }: Props) => (
	<ShortLabel issueKey={basicIssue.key} summary={basicIssue.summary} />
);
