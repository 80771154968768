// noinspection JSUnusedLocalSymbols

/**
 *******************************
 *** IMPORTANT - PLEASE READ ***
 *******************************
 * Whenever updating this file, you must also update the query string from the generated graphql.js file to the following file:
 * `src/packages/issue/issue-fetch-services-vendorless/src/services/issue-agg-data/main.tsx`.
 *
 * Run `yarn relay:issue-view` to automatically update the vendorless service file.
 *
 * This is done to avoid incurring the bundle size cost of including the entire generated file in the vendorless fetch service.
 */
/* eslint-disable @atlassian/relay/unused-fields, @atlassian/relay/must-colocate-fragment-spreads */
// TODO Decomp BENTO-12514 - re-enable @atlassian/relay/must-colocate-fragment-spreads - add useFragment layers from here to src_issueViewLayoutTemplatesItemList_ItemList

import { graphql } from 'react-relay';
import issueAggQuery, {
	type mainIssueAggQuery$data as mainIssueAggQueryResponse,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { extractProjectKey } from '../../common/utils/extract-project-key';
import { getMainIssueViewAggQueryVariables } from './utils';

// TODO Decomp BENTO-12514 - once each layer of the top level of the issue view app has its own useFragment, we shouldn't need this as each component will have its own fragment type. Or at least it will only be needed for rare usecases like syncing data to the legacy redux / sweet-state stores
// This type represents the data in the issue-view's JiraIssue graphql object from its fat-graphql query to AGG.
// This type is not nullable, but it should be nullable most of the time in case the data hasn't arrived yet, but that should be determined by each usecase's Props, as it's possible to pass this through to a component only if the data is available
export type IssueViewRelayFragment = mainIssueAggQueryResponse['jira']['issueByKey'];

// eslint-disable-next-line @atlassian/relay/must-use-inline
export const issueAggDataQueryGraphqlTag = graphql`
	query mainIssueAggQuery(
		$issueKey: String!
		$projectKey: String!
		$cloudId: ID!
		$issueViewRelayCascadingSelectFlag: Boolean!
		$giraAGGMigrationIsArchivedFlag: Boolean!
		$parentInIssueViewFlag: Boolean!
		$isCompassComponentsEnabled: Boolean!
		$giraAGGMigrationAttachmentsFlag: Boolean!
		$isRelayReleaseVersionFieldIssueViewIntegrationEnabled: Boolean!
		$issueViewRelayGoalsFlag: Boolean!
		$isCMDBAttributeIdRemoved: Boolean!
		$jsmSentimentCustomFieldFlag: Boolean!
		$isJPDPanelEnabled: Boolean!
		$giraAGGMigrationCommentsFlag: Boolean!
		$commentTargetId: String
		$issueViewRelayAssigneeFieldFlag: Boolean!
		$issueViewRelayNumberFieldFlag: Boolean!
		$issueViewRelayPriorityFieldFlag: Boolean!
		$issueViewRelaySprintFieldFlag: Boolean!
		$issueViewRelayAttachmentsFlag: Boolean!
		$issueViewRelaySingleLineTextFieldFlag: Boolean!
		$issueViewRelayDateFieldFlag: Boolean!
		$issueViewRelayProjectFieldFlag: Boolean!
		$issueViewRelayDateTimeFieldFlag: Boolean!
		$issueViewRelayMetadataFieldFlag: Boolean!
		$isJPDDeliveryLinkTypeFilteringEnabled: Boolean!
		$issueViewRelayUrlFieldFlag: Boolean!
		$issueViewRelayHeaders: Boolean!
		$issueViewRelayParentFieldFlag: Boolean!
		$issueViewRelaySummaryFieldFlag: Boolean!
		$issueViewRelayPeopleAndApproversFieldFlag: Boolean!
		$isPinFieldConfigurabilityEnabled: Boolean!
		$issueViewRelayColorFieldFlag: Boolean!
	) @preloadable {
		jira @required(action: THROW) {
			childIssuesLimit(cloudId: $cloudId)
			jpdDeliveryIssueLinkTypeId(cloudId: $cloudId)
				@include(if: $isJPDDeliveryLinkTypeFilteringEnabled)
			epicLinkFieldKey
			isSubtasksEnabled(cloudId: $cloudId) @optIn(to: "JiraIsSubtasksEnabled")
			globalTimeTrackingSettings(cloudId: $cloudId) {
				isJiraConfiguredTimeTrackingEnabled
				workingHoursPerDay
				workingDaysPerWeek
				defaultFormat
				defaultUnit
			}
			userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
				isIssueViewAttachmentReferenceFlagDismissed
				isIssueViewChildIssuesLimitBestPracticeFlagDismissed
				isIssueViewHideDoneChildIssuesFilterEnabled
				isIssueViewPinnedFieldsBannerDismissed
				issueViewActivityFeedSortOrder
				issueViewAttachmentPanelViewMode
				issueViewPinnedFields(projectKey: $projectKey)
				issueViewPinnedFieldsBannerLastInteracted
				issueViewSidebarResizeRatio
				issueViewTimestampDisplayMode
				issueViewDefaultPinnedFieldsBannerProject(projectKey: $projectKey)
					@include(if: $isPinFieldConfigurabilityEnabled)
					@optIn(to: "JiraIssueViewDefaultPinnedFieldsBannerProject")
			}
			issueLinkTypes(cloudId: $cloudId) @optIn(to: "JiraIssueLinkTypes") {
				edges {
					node {
						linkTypeId
						inwards
						outwards
					}
				}
			}
			issueByKey(key: $issueKey, cloudId: $cloudId) {
				mediaReadToken(maxTokenLength: 65536)
					@include(if: $issueViewRelayAttachmentsFlag)
					@optIn(to: "JiraMediaReadTokenInIssue") {
					clientId
					endpointUrl
					tokenLifespanInSeconds
					tokensWithFiles {
						edges {
							node {
								token
							}
						}
					}
				}
				mediaUploadToken
					@include(if: $issueViewRelayAttachmentsFlag)
					@optIn(to: "JiraMediaUploadTokenInIssue") {
					... on JiraMediaUploadToken {
						endpointUrl
						clientId
						targetCollection
						token
						tokenDurationInMin
					}
					... on QueryError {
						errorId: identifier
						errorMessage: message
					}
				}
				screenId
				errorRetrievingData @required(action: LOG)
				issueId
				key
				isArchived @include(if: $giraAGGMigrationIsArchivedFlag)
				hierarchyLevelAbove {
					level @required(action: LOG)
					name @required(action: LOG)
				}
				hierarchyLevelBelow {
					level @required(action: LOG)
					name @required(action: LOG)
				}
				issueTypesForHierarchyAbove {
					edges {
						node {
							id
							issueTypeId @required(action: LOG)
							name @required(action: LOG)
							description
							avatar {
								xsmall
								small
								medium @required(action: LOG)
								large
							}
							hierarchy @required(action: LOG) {
								level @required(action: LOG)
								name @required(action: LOG)
							}
						}
					}
				}
				issueTypesForHierarchyBelow {
					edges {
						node {
							id
							issueTypeId @required(action: LOG)
							name @required(action: LOG)
							description
							avatar {
								xsmall
								small
								medium @required(action: LOG)
								large
							}
							hierarchy @required(action: LOG) {
								level @required(action: LOG)
								name @required(action: LOG)
							}
						}
					}
				}
				issueTypesForHierarchySame {
					edges {
						node {
							id
							issueTypeId @required(action: LOG)
							name @required(action: LOG)
							description
							avatar {
								xsmall
								small
								medium @required(action: LOG)
								large
							}
							hierarchy @required(action: LOG) {
								level @required(action: LOG)
								name @required(action: LOG)
							}
						}
					}
				}
				...src_issueViewLayoutTemplatesItemList_ItemList
					@arguments(
						issueViewRelayCascadingSelectFlag: $issueViewRelayCascadingSelectFlag
						issueViewRelayGoalsFlag: $issueViewRelayGoalsFlag
						jsmSentimentCustomFieldFlag: $jsmSentimentCustomFieldFlag
						issueViewRelayPriorityFieldFlag: $issueViewRelayPriorityFieldFlag
						issueViewRelayAssigneeFieldFlag: $issueViewRelayAssigneeFieldFlag
						issueViewRelayNumberFieldFlag: $issueViewRelayNumberFieldFlag
						issueViewRelaySprintFieldFlag: $issueViewRelaySprintFieldFlag
						issueViewRelaySingleLineTextFieldFlag: $issueViewRelaySingleLineTextFieldFlag
						issueViewRelayDateFieldFlag: $issueViewRelayDateFieldFlag
						issueViewRelayProjectFieldFlag: $issueViewRelayProjectFieldFlag
						issueViewRelayDateTimeFieldFlag: $issueViewRelayDateTimeFieldFlag
						issueViewRelayUrlFieldFlag: $issueViewRelayUrlFieldFlag
						issueViewRelayPeopleAndApproversFieldFlag: $issueViewRelayPeopleAndApproversFieldFlag
					)
				...prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions
				...ui_issueFieldDataClassification_IssueViewDataClassification
				...ui_issueViewLayoutMetaContainer_IssueViewMetaContainer
					@include(if: $issueViewRelayMetadataFieldFlag)
				...header_issueViewFoundation_HeaderItemWithoutCompactMode
					@include(if: $issueViewRelayHeaders)
				...headerActions_issueViewFoundation_HeaderActionsView @include(if: $issueViewRelayHeaders)
				...headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView
					@include(if: $issueViewRelayParentFieldFlag)
				...ui_issueViewLayoutSummaryField_IssueViewSummaryField
					@include(if: $issueViewRelaySummaryFieldFlag)
				issueColorField @include(if: $issueViewRelayColorFieldFlag) {
					...ui_issueViewLayoutColorField_IssueViewColorField
				}
				...view_issueViewFoundation_StatusView
				fields {
					edges {
						node {
							__typename
							...ui_issueClassificationBadge_IssueClassificationBadge @relay(mask: false)
							... on JiraAffectedServicesField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}

								fieldId
								name
								type
								description
								selectedAffectedServicesConnection {
									edges {
										node {
											serviceId @required(action: LOG)
											name
										}
									}
								}
								searchUrl
							}
							... on JiraAssetField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedAssetsConnection {
									edges {
										node {
											appKey
											originId
											serializedOrigin
											value
										}
									}
								}
								searchUrl
							}
							... on JiraBooleanField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								value
							}
							... on JiraCMDBField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								searchUrl
								isInsightAvailable
								wasInsightRequestSuccessful
								cmdbFieldConfig {
									attributesDisplayedOnIssue {
										edges {
											node
										}
									}
									attributesIncludedInAutoCompleteSearch {
										edges {
											node
										}
									}
									objectSchemaId
									multiple
								}
								selectedCmdbObjectsConnection {
									edges {
										node {
											label
											objectGlobalId
											objectId
											objectKey
											workspaceId @required(action: LOG)
											webUrl
											avatar {
												mediaClientConfig {
													clientId
													fileId
													issuer
													mediaBaseUrl
													mediaJwtToken
												}
												url48 @required(action: LOG)
											}
											objectType @required(action: LOG) {
												objectTypeId
												name
												objectSchemaId
												icon @required(action: LOG) {
													id
													name
													url16
													url48
												}
											}
											attributes {
												edges {
													node {
														attributeId @skip(if: $isCMDBAttributeIdRemoved)
														objectTypeAttributeId
														objectTypeAttribute @required(action: LOG) {
															label
															name
															defaultType {
																id
																name
															}
															type
														}
														objectAttributeValues {
															edges {
																node {
																	displayValue
																	searchValue
																	value
																	user {
																		accountId
																		name
																		picture
																	}
																	status {
																		category
																		id
																		name
																	}
																	group {
																		name
																	}
																	project {
																		id
																		name
																		key
																		avatar {
																			large
																		}
																	}
																	bitbucketRepo {
																		uuid
																		name
																		url
																		avatarUrl
																	}
																	opsgenieTeam {
																		name
																		id
																		url
																	}
																	referencedObject {
																		objectGlobalId
																		workspaceId
																		objectKey
																		label
																		avatar {
																			mediaClientConfig {
																				clientId
																				fileId
																				issuer
																				mediaBaseUrl
																				mediaJwtToken
																			}
																			url48
																		}
																		objectId
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
							... on JiraCascadingSelectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								cascadingOption {
									parentOptionValue {
										id
										optionId
										value
										isDisabled
									}
									childOptionValue {
										id
										optionId
										value
										isDisabled
									}
								}
								cascadingOptions @skip(if: $issueViewRelayCascadingSelectFlag) {
									edges {
										node {
											parentOptionValue {
												id
												optionId
												value
												isDisabled
											}
											childOptionValues {
												id
												optionId
												value
												isDisabled
											}
										}
									}
								}
							}
							... on JiraCheckboxesField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
								checkboxesFieldOptions: fieldOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
							}
							... on JiraColorField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								color {
									id
									colorKey
								}
							}
							... on JiraComponentsField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedComponentsConnection {
									edges {
										node {
											id
											componentId
											name
											description
											ari @include(if: $isCompassComponentsEnabled)
											metadata @include(if: $isCompassComponentsEnabled)
										}
									}
								}
								components {
									edges {
										node {
											id
											componentId
											name
											description
											ari @include(if: $isCompassComponentsEnabled)
											metadata @include(if: $isCompassComponentsEnabled)
										}
									}
								}
							}
							... on JiraConnectDateTimeField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								dateTime
							}
							... on JiraConnectMultipleSelectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
								connectMultiSelectFieldOptions: fieldOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
							}
							... on JiraConnectNumberField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								number
							}
							... on JiraConnectRichTextField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								richText {
									plainText
									adfValue {
										json
									}
								}
								renderer
							}
							... on JiraConnectSingleSelectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								fieldOption {
									id
									optionId
									value
									isDisabled
								}
								connectSingleSelectFieldOptions: fieldOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
								searchUrl
							}
							... on JiraConnectTextField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								text
							}
							... on JiraDatePickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								date
							}
							... on JiraDateTimePickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								dateTime
							}
							... on JiraEpicLinkField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								epic {
									id
									issueId
									name
									key
									summary
									color
									done
								}
								searchUrl
							}
							... on JiraFlagField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								flag {
									isFlagged
								}
							}
							... on JiraForgeGroupField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedGroup {
									id
									groupId
									name
								}
								renderer
							}
							... on JiraForgeGroupsField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedGroupsConnection {
									edges {
										node {
											id
											groupId
											name
										}
									}
								}
								renderer
							}
							... on JiraForgeNumberField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								number
								renderer
							}
							... on JiraForgeObjectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								object
								renderer
							}
							... on JiraForgeStringField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								text
								renderer
							}
							... on JiraForgeStringsField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedLabelsConnection {
									edges {
										node {
											labelId
											name
										}
									}
								}
								renderer
								searchUrl
							}
							... on JiraForgeUserField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								user {
									__typename
									accountId
									accountStatus
									name
									picture
									... on AtlassianAccountUser {
										email
										zoneinfo
										locale
									}
									... on CustomerUser {
										email
										zoneinfo
										locale
									}
								}
								renderer
								searchUrl
							}
							... on JiraForgeUsersField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedUsersConnection {
									edges {
										node {
											__typename
											accountId
											accountStatus
											name
											picture
											... on AtlassianAccountUser {
												email
												zoneinfo
												locale
											}
											... on CustomerUser {
												email
												zoneinfo
												locale
											}
										}
									}
								}
								renderer
								searchUrl
							}
							... on JiraIssueRestrictionField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedRolesConnection {
									edges {
										node {
											id
											roleId
										}
									}
								}
								searchUrl
							}
							... on JiraIssueTypeField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								issueType {
									id
									issueTypeId @required(action: LOG)
									name @required(action: LOG)
									description
									avatar {
										xsmall
										small
										medium @required(action: LOG)
										large
									}
									hierarchy @required(action: LOG) {
										level @required(action: LOG)
										name @required(action: LOG)
									}
								}
							}
							... on JiraLabelsField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedLabelsConnection {
									edges {
										node {
											labelId
											name
										}
									}
								}
								searchUrl
							}
							... on JiraMultipleGroupPickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedGroupsConnection {
									edges {
										node {
											groupId
											name
										}
									}
								}
								searchUrl
							}
							... on JiraMultipleSelectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
								searchUrl
							}
							... on JiraMultipleSelectUserPickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedUsersConnection {
									edges {
										node {
											__typename
											accountId
											accountStatus
											name
											picture
											... on AtlassianAccountUser {
												email
												zoneinfo
												locale
											}
											... on CustomerUser {
												email
												zoneinfo
												locale
											}
										}
									}
								}
								searchUrl
							}
							... on JiraMultipleVersionPickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedVersionsConnection {
									edges {
										node {
											id
											versionId
											name
											status
											description
										}
									}
								}
								multiPickerVersions: versions
									@skip(if: $isRelayReleaseVersionFieldIssueViewIntegrationEnabled) {
									edges {
										node {
											id
											versionId
											name
											status
											description
										}
									}
								}
							}
							... on JiraNumberField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								number
								isStoryPointField
							}
							... on JiraOriginalTimeEstimateField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
											isEditable
										}
									}
									id
								}
								fieldId
								name
								type
								description
								originalEstimate {
									timeInSeconds
								}
							}
							... on JiraParentIssueField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								parentVisibility {
									hasEpicLinkFieldDependency
									canUseParentLinkField
								}
								parentIssue {
									id
									issueId
									key
									webUrl
									issueColorField @include(if: $parentInIssueViewFlag) {
										color {
											colorKey
										}
									}
									fieldsById(ids: ["issuetype", "project", "summary"]) {
										edges {
											node {
												__typename
												... on JiraIssueTypeField {
													fieldId
													name
													type
													description
													issueType {
														id
														issueTypeId @required(action: LOG)
														name @required(action: LOG)
														description
														avatar {
															xsmall
															small
															medium @required(action: LOG)
															large
														}
														hierarchy @required(action: LOG) {
															level @required(action: LOG)
															name @required(action: LOG)
														}
													}
												}
												# Review the required attributes as part of the fields migration to Relay.
												# Properties marked as required her eare not utilized in the versions migrated to Relay and can be removed completely.
												# https://hello.jira.atlassian.cloud/browse/RELAY-297
												... on JiraProjectField {
													fieldId
													name
													type
													description
													searchUrl
													project {
														key
														name
														projectId @required(action: LOG)
														canSetIssueRestriction
														projectStyle
														projectType
														status
														avatar @required(action: LOG) {
															medium
															large @required(action: LOG)
														}
														similarIssues {
															featureEnabled
														}
														navigationMetadata {
															... on JiraSoftwareProjectNavigationMetadata {
																boardId
															}
														}
													}
												}
												... on JiraSingleLineTextField {
													fieldId
													name
													type
													description
													text
												}
											}
										}
									}
								}
							}
							... on JiraPeopleField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedUsersConnection {
									edges {
										node {
											__typename
											accountId
											accountStatus
											name
											picture
											... on AtlassianAccountUser {
												email
												zoneinfo
												locale
											}
											... on CustomerUser {
												email
												zoneinfo
												locale
											}
										}
									}
								}
								isMulti
								searchUrl
							}
							... on JiraPriorityField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								priority {
									priorityId
									name
									iconUrl
									sequence
									color
								}
								priorities {
									edges {
										node {
											priorityId
											name
											iconUrl
											sequence
											color
										}
									}
								}
							}
							... on JiraProformaFormsField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								proformaForms {
									hasProjectForms
									hasIssueForms
									isHarmonisationEnabled
								}
							}
							... on JiraProjectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								searchUrl
								project {
									key
									name
									projectId @required(action: LOG)
									canSetIssueRestriction
									projectStyle
									projectType
									status
									avatar @required(action: LOG) {
										medium
										large @required(action: LOG)
									}
									similarIssues {
										featureEnabled
									}
									navigationMetadata {
										... on JiraSoftwareProjectNavigationMetadata {
											boardId
										}
									}
								}
							}
							... on JiraRadioSelectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedOption {
									id
									optionId
									value
									isDisabled
								}
								radioSelectFieldOptions: fieldOptions {
									edges {
										node {
											id
											optionId
											value
											isDisabled
										}
									}
								}
							}
							... on JiraResolutionField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								resolution {
									id
									resolutionId
									name
								}
								resolutions {
									edges {
										node {
											id
											resolutionId
											name
										}
									}
								}
							}
							... on JiraRichTextField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								richText {
									plainText
									adfValue {
										json
									}
								}
								renderer
							}
							... on JiraSecurityLevelField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								securityLevel {
									name
									securityId
									description
								}
								securityLevels {
									edges {
										node {
											name
											securityId
											description
										}
									}
								}
							}
							... on JiraServiceManagementApprovalField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								activeApproval {
									id
									approvalState
									approvedStatus {
										categoryId
										name
									}
									approverPrincipals {
										edges {
											node {
												__typename
												... on JiraServiceManagementUserApproverPrincipal {
													user @required(action: LOG) {
														accountId @required(action: LOG)
														name @required(action: LOG)
														picture @required(action: LOG)
													}
													jiraRest @required(action: LOG)
												}
												... on JiraServiceManagementGroupApproverPrincipal {
													groupId @required(action: LOG)
													name @required(action: LOG)
													memberCount @required(action: LOG)
													approvedCount @required(action: LOG)
												}
											}
										}
									}
									approvers {
										edges {
											node {
												approver @required(action: LOG) {
													accountId @required(action: LOG)
													accountStatus @required(action: LOG)
													name @required(action: LOG)
													picture @required(action: LOG)

													... on AtlassianAccountUser {
														email
														zoneinfo
													}
													... on CustomerUser {
														email
														zoneinfo
													}
												}
												approverDecision @required(action: LOG)
											}
										}
									}
									canAnswerApproval @required(action: LOG)
									configurations @required(action: LOG) {
										approversConfigurations @required(action: LOG) {
											type @required(action: LOG)
											fieldName @required(action: LOG)
											fieldId @required(action: LOG)
										}
										condition @required(action: LOG) {
											type @required(action: LOG)
											value @required(action: LOG)
										}
									}
									createdDate
									decisions {
										edges {
											node {
												approver @required(action: LOG) {
													accountId @required(action: LOG)
													accountStatus @required(action: LOG)
													name @required(action: LOG)
													picture @required(action: LOG)

													... on AtlassianAccountUser {
														email
														zoneinfo
													}
													... on CustomerUser {
														email
														zoneinfo
													}
												}
												approverDecision @required(action: LOG)
											}
										}
									}
									excludedApprovers {
										edges {
											node {
												accountId @required(action: LOG)
												accountStatus @required(action: LOG)
												name @required(action: LOG)
												picture @required(action: LOG)

												... on AtlassianAccountUser {
													email
													zoneinfo
												}
												... on CustomerUser {
													email
													zoneinfo
												}
											}
										}
									}
									finalDecision @required(action: LOG)
									id
									name @required(action: LOG)
									pendingApprovalCount @required(action: LOG)
									status {
										id
										name
										categoryId
									}
								}
								completedApprovalsConnection {
									edges {
										node {
											id
											name @required(action: LOG)
											finalDecision @required(action: LOG)
											approvers {
												edges {
													node {
														approver @required(action: LOG) {
															accountId @required(action: LOG)
															accountStatus @required(action: LOG)
															name @required(action: LOG)
															picture @required(action: LOG)

															... on AtlassianAccountUser {
																email
																zoneinfo
															}
															... on CustomerUser {
																email
																zoneinfo
															}
														}
														approverDecision @required(action: LOG)
													}
												}
											}
											createdDate
											completedDate @required(action: LOG)
											status {
												name
												categoryId
											}
										}
									}
								}
							}
							... on JiraServiceManagementMajorIncidentField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								majorIncident
							}
							... on JiraBooleanField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								value
							}
							... on JiraServiceManagementOrganizationField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedOrganizationsConnection {
									edges {
										node {
											organizationId
											organizationName
											domain
										}
									}
								}
								searchUrl
							}
							... on JiraServiceManagementPeopleField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedUsersConnection {
									edges {
										node {
											__typename
											accountId
											accountStatus
											name
											picture
											... on AtlassianAccountUser {
												email
												zoneinfo
												locale
											}
											... on CustomerUser {
												email
												zoneinfo
												locale
											}
										}
									}
								}
								searchUrl
							}
							... on JiraServiceManagementRequestFeedbackField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								feedback {
									rating
								}
							}
							... on JiraServiceManagementRequestLanguageField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								language {
									languageCode
									displayName
								}
								languages {
									languageCode
									displayName
								}
							}
							... on JiraServiceManagementRequestTypeField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								requestType {
									id
									requestTypeId
									name
									issueType {
										id
										issueTypeId
									}
									avatar {
										xsmall
										small
										medium
										large
									}
								}
							}
							... on JiraServiceManagementRespondersField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								searchUrl
								respondersConnection {
									edges {
										node {
											__typename
											... on JiraServiceManagementUserResponder {
												user {
													canonicalAccountId
													picture
													name
												}
											}
											... on JiraServiceManagementTeamResponder {
												teamId
												teamName
											}
										}
									}
								}
							}
							... on JiraSingleGroupPickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedGroup {
									id
									groupId
									name
								}
								searchUrl
							}
							... on JiraSingleLineTextField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								text
							}
							... on JiraSingleSelectField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								fieldOption {
									id
									optionId
									value
									isDisabled
								}
								searchUrl
							}
							... on JiraSingleSelectUserPickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								user {
									__typename
									accountId
									accountStatus
									name
									picture
									... on AtlassianAccountUser {
										zoneinfo
										locale
									}
									... on CustomerUser {
										zoneinfo
										locale
									}
								}
								searchUrl
							}
							... on JiraSingleVersionPickerField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								version {
									id
									versionId
									name
									iconUrl
									status
									description
									startDate
									releaseDate
								}
								singlePickerVersions: versions {
									edges {
										node {
											id
											versionId
											name
											iconUrl
											status
											description
											startDate
											releaseDate
										}
									}
								}
							}
							... on JiraSprintField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedSprintsConnection {
									edges {
										node {
											id
											sprintId @required(action: LOG)
											name @required(action: LOG)
											state
											boardName
											startDate
											endDate
											completionDate
											goal
										}
									}
								}
								searchUrl
							}
							... on JiraStatusField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								status {
									id
									name @required(action: LOG)
									description
									statusId
									statusCategory @required(action: LOG) {
										id
										statusCategoryId
									}
								}
							}
							... on JiraTeamField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedTeam {
									teamId
									name
									isShared
									avatar {
										medium
									}
								}
							}
							... on JiraTeamViewField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								selectedTeam {
									jiraSuppliedVisibility
									jiraSuppliedName
									jiraSuppliedId
									jiraSuppliedTeamId
									jiraSuppliedAvatar {
										xsmall
										small
										medium
										large
									}
								}
								searchUrl
							}
							... on JiraTimeTrackingField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								originalEstimate {
									timeInSeconds
								}
								remainingEstimate {
									timeInSeconds
								}
								timeSpent {
									timeInSeconds
								}
							}
							... on JiraUrlField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								uri
							}
							... on JiraVotesField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								vote {
									hasVoted
									count
								}
							}
							... on JiraWatchesField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								watch {
									isWatching
									count
								}
							}
							... on JiraForgeDatetimeField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								fieldId
								name
								type
								description
								dateTime
								renderer
							}
							... on JiraForgeDateField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								date
								fieldId
								name
								type
								description
								renderer
							}
							# So the reduced schema in issue-fetch-mocks picks it up
							... on JiraServiceManagementEntitlementField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								id
								__id
								name
								# eslint-disable-next-line @atlassian/relay/unused-fields # used by agg transformer
								type
								fieldId
								selectedEntitlement {
									id
									product {
										id
										name
									}
									entity {
										__typename
										... on JiraServiceManagementEntitlementCustomer {
											id
										}
										... on JiraServiceManagementEntitlementOrganization {
											id
										}
									}
								}
							}
							... on JiraServiceManagementSentimentField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								id
								__id
								name
								type
								fieldId
								description
								sentiment {
									sentimentId
									name
								}
							}
							... on JiraGoalsField {
								... on JiraIssueField {
									isEditableInIssueView
									... on JiraIssueFieldConfiguration {
										fieldConfig {
											isRequired
										}
									}
									id
								}
								__typename
								type
								fieldId
								name
								description
								selectedGoals(first: 1) @include(if: $issueViewRelayGoalsFlag) {
									totalCount
									edges {
										node {
											id
											name
											status
										}
									}
								}
							}
						}
					}
				}
				childIssues {
					__typename
					... on JiraChildIssuesWithinLimit {
						issues(activeProjectsOnly: true) {
							edges {
								node {
									key
									issueId
									webUrl
									isResolved
									storyPointEstimateField {
										__typename
										fieldId
										type
										number
										isStoryPointField
									}
									storyPointsField {
										__typename
										fieldId
										type
										number
										isStoryPointField
									}
									fieldsById(
										ids: [
											"assignee"
											"created"
											"issuetype"
											"priority"
											"status"
											"summary"
											"timetracking"
											"updated"
										]
									) {
										edges {
											node {
												__typename
												... on JiraIssueTypeField {
													fieldId
													type
													issueType {
														issueTypeId @required(action: LOG)
														name @required(action: LOG)
														avatar {
															medium @required(action: LOG)
														}
														hierarchy @required(action: LOG) {
															level @required(action: LOG)
														}
													}
												}
												... on JiraSingleLineTextField {
													fieldId
													type
													text
												}
												... on JiraPriorityField {
													fieldId
													type
													priority {
														priorityId
														name
														sequence
														iconUrl
													}
												}
												... on JiraStatusField {
													fieldId
													type
													status {
														name @required(action: LOG)
														statusId
														statusCategory @required(action: LOG) {
															statusCategoryId
														}
													}
												}
												... on JiraSingleSelectUserPickerField {
													fieldId
													type
													user {
														__typename
														accountId
														accountStatus
														name
														picture
													}
												}
												... on JiraTimeTrackingField {
													fieldId
													type
													originalEstimate {
														timeInSeconds
													}
													remainingEstimate {
														timeInSeconds
													}
													timeSpent {
														timeInSeconds
													}
												}
												... on JiraDateTimePickerField {
													fieldId
													type
													dateTime
												}
											}
										}
									}
								}
							}
						}
					}
					... on JiraChildIssuesExceedingLimit {
						search
					}
				}
				issueLinks(first: 1000) {
					edges {
						node {
							id
							issueLinkId @required(action: LOG)
							direction @required(action: LOG)
							relationName @required(action: LOG)
							type @required(action: LOG) {
								linkTypeId @required(action: LOG)
							}
							issue {
								id
								issueId
								key
								webUrl
								isResolved
								fieldsById(
									ids: ["assignee", "issuetype", "priority", "status", "summary", "project"]
								) {
									edges {
										node {
											__typename
											... on JiraStatusField {
												fieldId
												name
												type
												status {
													id
													name @required(action: LOG)
													statusId
													statusCategory @required(action: LOG) {
														id
														statusCategoryId
														key
														name @required(action: LOG)
														colorName
													}
												}
											}
											... on JiraPriorityField {
												fieldId
												name
												type
												priority {
													priorityId
													name
													sequence
													iconUrl
													color
												}
											}
											... on JiraIssueTypeField {
												fieldId
												name
												type
												issueType {
													id
													issueTypeId @required(action: LOG)
													name @required(action: LOG)
													avatar {
														xsmall
														small
														medium @required(action: LOG)
														large
													}
													hierarchy @required(action: LOG) {
														level @required(action: LOG)
														name @required(action: LOG)
													}
												}
											}
											... on JiraSingleLineTextField {
												fieldId
												name
												type
												text
											}
											... on JiraSingleSelectUserPickerField {
												fieldId
												name
												type
												user {
													__typename
													accountId
													accountStatus
													name
													picture
													... on AtlassianAccountUser {
														zoneinfo
														locale
													}
													... on CustomerUser {
														zoneinfo
														locale
													}
												}
											}
											... on JiraProjectField {
												fieldId
												name
												type
												description
												searchUrl
												project {
													key
													name
													projectId @required(action: LOG)
													projectStyle
													canSetIssueRestriction
													projectType
													status
													similarIssues {
														featureEnabled
													}
													avatar @required(action: LOG) {
														medium
														large @required(action: LOG)
													}
													navigationMetadata {
														... on JiraSoftwareProjectNavigationMetadata {
															boardId
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
					...ui_issueViewJpdIdeas_JPDIdeasPanel @include(if: $isJPDPanelEnabled)
				}
				attachments(first: 12, sortBy: { field: CREATED, order: DESC })
					@connection(key: "issue_attachments")
					@include(if: $giraAGGMigrationAttachmentsFlag) {
					edges {
						node {
							__typename
							... on JiraPlatformAttachment {
								attachmentId
								fileName
								mimeType
								mediaApiFileId
								created
								fileSize
								parentName
								author {
									accountId
								}
								parentId
								hasRestrictedParent
							}
						}
					}
				}
				comments(
					targetId: $commentTargetId
					beforeTarget: 5
					afterTarget: 5
					first: 5
					sortBy: { field: CREATED, order: DESC }
				) @include(if: $giraAGGMigrationCommentsFlag) {
					edges {
						node {
							__typename
							commentId
							created
							updated
							author {
								name
								accountId
								picture
							}
							updateAuthor {
								name
								accountId
								picture
							}
							richText {
								adfValue {
									json
								}
							}
							permissionLevel {
								group {
									id
									name
									groupId
								}
								role {
									id
									name
								}
							}
							... on JiraServiceManagementComment {
								authorCanSeeRequest
								eventOccurredAt
								visibility
								jsdIncidentActivityViewHidden
							}
						}
					}
				}
			}
		}
	}
`;

export const ISSUE_AGG_OPERATION_NAME = issueAggQuery.params.name;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issueKey: string, cloudId: string) => {
	const projectKey = extractProjectKey(issueKey);

	return {
		operationName: ISSUE_AGG_OPERATION_NAME,
		query: issueAggQuery.params.text,
		variables: {
			cloudId,
			issueKey,
			projectKey,
			...getMainIssueViewAggQueryVariables(),
		},
	};
};
