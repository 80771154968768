/* eslint-disable jira/react-magnetic-di/no-unneeded */
import React from 'react';
import { di } from 'react-magnetic-di';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { ProviderError } from '@atlaskit/collab-provider';
import { createSocketIOCollabProvider } from '@atlaskit/collab-provider/socket-io-provider';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import { fg } from '@atlassian/jira-feature-gating';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { WithWhyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { cloudifyViewAri } from '../../common/utils/ari';
import { IdeaViewStartMark, IdeaViewEndMark, MARKS } from '../../common/utils/metrics/idea-view';
import type { User } from '../../services/jira/get-user';
import actions from './actions';
import type { State, Props, CollabEditProvider, CollabEventPresenceData } from './types';
import { permissionTokenRefresh } from './utils/permission-token-refresh';

type Actions = typeof actions;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getCollabServiceUrl = (): string => `${window.location.origin}/jcollab`;

const initialState: State = {
	userCache: {},
	provider: undefined,
	presence: [],
	containerProps: undefined,
	criticalGetCollabTokenErrorHappened: false,
};

const CollabStore = createPolarisStore<State, Actions>({
	name: 'PolarisCollabStore',
	initialState,
	actions,
});

export const createCollabProviderInitialState = (clientId?: string) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	({
		// @ts-expect-error - TS2352: Conversion of type '{ key: string; spec: { config: { clientID: string | undefined; }; }; }[]' to type 'Plugin[]' may be a mistake because neither type sufficiently overlaps with the other.
		plugins: [{ key: 'collab$', spec: { config: { clientID: clientId } } }],
	}) as Partial<EditorState> as EditorState;

const emptyDoc: ADF = {
	version: 1,
	type: 'doc',
	content: [{ type: 'paragraph', content: [] }],
};

export const initializeCollabProvider = ({
	provider,
	userId,
	doc = emptyDoc,
}: {
	provider: CollabEditProvider;
	userId: string;
	doc?: ADF;
}) => {
	const initCollab = () => {
		provider.off('init', initCollab);
		// @ts-expect-error - Property 'channel' is private
		provider.channel.eventEmitter.emit('init', {
			doc,
			userId,
			version: 0,
			metadata: {},
		});
	};
	provider.on('init', initCollab);
};

const cleanup =
	() =>
	({ getState, setState }: StoreActionApi<State>) => {
		const { provider } = getState();
		if (provider !== undefined) {
			provider.destroy();
			setState({
				provider: undefined,
				presence: [],
			});
		}
	};

const updateProvider =
	() =>
	(
		actionApi: StoreActionApi<State>,
		{ apolloClient, cloudId, viewAri, accountId, createAnalyticsEvent, onError, embedded }: Props,
	) => {
		if (embedded) {
			return;
		}

		const { setState, dispatch, getState } = actionApi;

		cleanup()(actionApi);

		const { criticalGetCollabTokenErrorHappened } = getState();
		if (criticalGetCollabTokenErrorHappened) {
			return;
		}

		if (viewAri === undefined) {
			// invalid props, do not create collab provider
			return;
		}

		const onPresence = (presenceData: CollabEventPresenceData) => {
			if (presenceData) {
				if (presenceData.left) {
					dispatch(actions.presenceLeft(presenceData.left));
				}

				if (presenceData.joined) {
					dispatch(actions.presenceJoined(presenceData.joined));
				}
			}
		};

		const onProviderError = (error: ProviderError) => {
			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'createCollabProvider failure', {
				status: error.status,
				code: error.code,
				message: error.message,
			});

			getState().provider?.destroy();

			setState({
				provider: undefined,
				presence: [],
			});
		};

		const onCriticalCollabTokenError = (error: Error, reason: String, status: Number) => {
			const { provider } = getState();

			if (criticalGetCollabTokenErrorHappened) {
				// updateProvider may be called more than once in parallel.
				// And we want to be sure we do this only once.
				return;
			}

			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'createCollabProvider failure', {
				status,
				code: reason,
				message: error.message,
			});

			setState({
				criticalGetCollabTokenErrorHappened: true,
				provider: undefined,
				presence: [],
			});

			provider?.destroy();
			onError?.(error, reason);
		};

		const getUserAndUpdatePresence = async (id?: string): Promise<User | {}> =>
			dispatch(actions.getUser(id));

		const viewAriWithCloud = cloudifyViewAri(cloudId, viewAri);

		const provider = createSocketIOCollabProvider({
			url: getCollabServiceUrl(),
			documentAri: viewAriWithCloud,
			// @ts-expect-error - getUser might return {}
			getUser: getUserAndUpdatePresence,
			permissionTokenRefresh: permissionTokenRefresh.bind(
				null,
				apolloClient,
				viewAri,
				onCriticalCollabTokenError,
			),
			isPresenceOnly: fg('jira_presence_use_cp_presence_only'),
		});

		// Add this as collab provider ^ 9 change Collab Participant type
		// Expected the data still there https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/94145/overview?commentId=5310890
		// @ts-expect-error - Argument of type '(presenceData: CollabEventPresenceData) => void' is not assignable to parameter of type '(args: CollabEventPresenceData) => void'.
		provider.on('presence', onPresence);
		provider.on('error', onProviderError);

		provider.initialize(() => createCollabProviderInitialState(accountId));
		initializeCollabProvider({ provider, userId: accountId ?? 'unidentified' });
		setState({ provider });
	};

export const {
	Container: CollabContainerInternal,
	useActions,
	createHook,
	createHigherLevelHook,
} = createJpdContainer<Props, State, Actions>(CollabStore, {
	onUpdate: updateProvider,
	onInit: updateProvider,
	onCleanup: cleanup,
});
export const CollabContainer = WithWhyDidYouRenderPlaceholder((props: Props) => {
	di(CollabContainerInternal);
	const { children, ...rest } = props;
	return (
		<>
			<IdeaViewEndMark mark={MARKS.COLLAB_CONTAINER_LOADING_STATE} />
			<IdeaViewStartMark mark={MARKS.COLLAB_CONTAINER} />
			<CollabContainerInternal {...rest}>
				<IdeaViewEndMark mark={MARKS.COLLAB_CONTAINER} />
				{children}
			</CollabContainerInternal>
		</>
	);
}, 'CollabContainer');
