import {
	type IntervalFieldFilterLegacyValue,
	type NumericFieldFilterValue,
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
	type IntervalFieldFilterAbsoluteDatesValue,
	type IntervalFieldFilterRollingDatesCurrentValue,
	type IntervalFieldFilterRollingDatesPastNextValue,
	type IntervalFieldFilterEmptyValue,
	type IntervalFieldFilterNotEmptyValue,
} from './types';

export const createStartAfterNowValue = (numericValue: number): IntervalFieldFilterLegacyValue => ({
	operator: START_AFTER_NOW,
	numericValue,
});

export const createStartBeforeNowValue = (
	numericValue: number,
): IntervalFieldFilterLegacyValue => ({
	operator: START_BEFORE_NOW,
	numericValue,
});

export const createEndAfterNowValue = (numericValue: number): IntervalFieldFilterLegacyValue => ({
	operator: END_AFTER_NOW,
	numericValue,
});

export const createEndBeforeNowValue = (numericValue: number): IntervalFieldFilterLegacyValue => ({
	operator: END_BEFORE_NOW,
	numericValue,
});

export const intervalAbsoluteDatesFilter = ({
	start,
	end,
}: {
	start?: string;
	end?: string;
}): IntervalFieldFilterAbsoluteDatesValue => ({
	type: 'ABSOLUTE_DATES',
	start,
	end,
});

export function intervalRollingDatesFilters(
	arg: Omit<IntervalFieldFilterRollingDatesPastNextValue, 'type'>,
): IntervalFieldFilterRollingDatesPastNextValue;

export function intervalRollingDatesFilters(
	arg: Omit<IntervalFieldFilterRollingDatesCurrentValue, 'type'>,
): IntervalFieldFilterRollingDatesCurrentValue;

export function intervalRollingDatesFilters(
	arg:
		| Omit<IntervalFieldFilterRollingDatesPastNextValue, 'type'>
		| Omit<IntervalFieldFilterRollingDatesCurrentValue, 'type'>,
): IntervalFieldFilterRollingDatesPastNextValue | IntervalFieldFilterRollingDatesCurrentValue {
	return {
		type: 'ROLLING_DATES',
		...arg,
	};
}

export const intervalEmptyFilter = (): IntervalFieldFilterEmptyValue => ({
	operator: 'EQ',
	text: null,
	value: null,
});

export const intervalNotEmptyFilter = (): IntervalFieldFilterNotEmptyValue => ({
	operator: 'NEQ',
	text: null,
	value: null,
});

export const ltFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'LT',
	numericValue,
});

export const lteFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'LTE',
	numericValue,
});

export const gtFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'GT',
	numericValue,
});

export const gteFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'GTE',
	numericValue,
});

export const eqFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'EQ',
	numericValue,
});

export const neqFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'NEQ',
	numericValue,
});

export const evaluateNumericFilterValues = (
	filterValues: NumericFieldFilterValue[],
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	value: number | void,
): boolean =>
	filterValues.find(({ operator, numericValue }) => {
		switch (operator) {
			case 'LTE':
				return (
					!(numericValue === value) &&
					(numericValue === undefined || value === undefined || !(value < numericValue))
				);
			case 'GTE':
				return (
					!(numericValue === value) &&
					(numericValue === undefined || value === undefined || !(value > numericValue))
				);
			case 'LT':
				return numericValue === undefined || value === undefined || !(value < numericValue);
			case 'GT':
				return numericValue === undefined || value === undefined || !(value > numericValue);
			case 'EQ':
				return numericValue !== value;
			case 'NEQ':
				return numericValue === value;
			default:
				return false;
		}
	}) === undefined;
