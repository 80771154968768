import React from 'react';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ExternalReferenceField } from '../../../../../fields/external-reference/main';
import messages from './messages';

type Props = {
	field: Field;
	groupIdentity: string | undefined;
};

export const optionNoValueOptionMessage = messages.noValueOption;

export const ExternalReferenceOptionRenderer = ({ field, groupIdentity }: Props) => {
	const { formatMessage } = useIntl();
	if (groupIdentity === undefined) {
		return <Box paddingInlineStart="space.100">{formatMessage(optionNoValueOptionMessage)}</Box>;
	}
	return (
		<Box xcss={containerStyles}>
			<ExternalReferenceField
				isEditable={false}
				fieldKey={field.key}
				value={[groupIdentity]}
				onUpdate={noop}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	whiteSpace: 'normal',
	marginInlineEnd: 'space.100',
});
