import debounce from 'lodash/debounce';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getAriConfig, type Ari } from '@atlassian/jira-platform-ari';
import type { FieldRollup } from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import {
	type Filter,
	INTERVAL_FIELD_FILTER,
	GENERIC_FIELD_FILTER,
	NUMERIC_FIELD_FILTER,
	TEXT_FIELD_FILTER,
	type IntervalFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	isIntervalFieldFilterEmpty,
	isIntervalFieldFilterLegacy,
	isIntervalFieldFilterNotEmpty,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import type { PolarisViewTableColumnSize } from '@atlassian/jira-polaris-domain-view/src/list/types.tsx';
import type { PolarisMatrixConfig } from '@atlassian/jira-polaris-domain-view/src/matrix/types.tsx';
import type { PolarisTimelineConfig } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { AccessNotPermittedError } from '@atlassian/jira-polaris-remote-view/src/common/errors/access-not-permitted-error.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
import {
	FILTER_KIND,
	type UpdateSort,
	type UpdateFilter,
	type UpdateMatrixConfig,
	type UpdateTimelineConfig,
	type UpdateTableColumnSize,
	type UpdateFieldRollup,
} from '@atlassian/jira-polaris-remote-view/src/common/types.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { getPinnedViewId } from '../../selectors/view/view-sets/index.tsx';
import type { Props, State } from '../../types';
import { deleteView } from '../delete';
import { logViewError } from '../utils/errors';
import { updateViewState } from '../utils/state';
import { findView } from '../utils/views';
import { createUpdateErrorMessageLogSafe } from './utils';

type AfterSaveProps = {
	id: string;
	viewId?: Ari;
	uuid?: string;
	error?: Error;
	updatedAt: string | null;
	onAfterSave?: (savedView: View | undefined) => void;
};

export const beforeSave =
	(id: string): Action<State> =>
	async ({ getState, setState }) => {
		const { viewSets } = updateViewState(
			getState().viewSets,
			(view) => view.id === id,
			(view: View) => ({
				...view,
				saving: true,
				saveError: undefined,
				modified: false,
			}),
		);
		setState({ viewSets });
	};

export const afterSave =
	({ id, error, viewId, uuid, updatedAt, onAfterSave }: AfterSaveProps): Action<State, Props> =>
	async ({ getState, setState }, { onTryAddCurrentUserAsSiteCreator }) => {
		const viewAriConfig = viewId !== undefined ? getAriConfig(viewId) : undefined;
		const { viewSets } = updateViewState(
			getState().viewSets,
			(view) => view.id === id,
			(view: View) => ({
				...view,
				updatedAtTimestamp: updatedAt,
				viewId: viewId !== undefined ? viewId : view.viewId,
				uuid: uuid !== undefined ? uuid : view.uuid,
				slug: viewAriConfig !== undefined ? viewAriConfig.resourceId : view.slug,
				saveError: error,
				saving: false,
			}),
		);
		if (onAfterSave !== undefined) {
			const updatedView = findView(viewSets || [], ({ id: vid }) => id === vid);
			onAfterSave(updatedView);
		}

		setState({ viewSets });

		// set current user as site creator
		onTryAddCurrentUserAsSiteCreator();
	};

const transformIntervalFilter = (filter: IntervalFieldFilter): UpdateFilter | undefined => {
	if (!fg('polaris_better_date_filters')) {
		if (isIntervalFieldFilterLegacy(filter)) {
			return {
				kind: FILTER_KIND.INTERVAL,
				field: filter.field,
				values: filter.values.map((value) => ({
					value: value.numericValue !== undefined ? value.numericValue : null,
					operator: value.operator,
				})),
			};
		}
	}

	if (isIntervalFieldFilterLegacy(filter)) {
		return {
			kind: FILTER_KIND.INTERVAL,
			field: filter.field,
			values: filter.values.map((value) => ({
				value: value.numericValue !== undefined ? value.numericValue : null,
				operator: value.operator,
			})),
		};
	}

	if (isIntervalFieldFilterEmpty(filter) || isIntervalFieldFilterNotEmpty(filter)) {
		return {
			kind: FILTER_KIND.INTERVAL,
			field: filter.field,
			values: filter.values.map((value) => ({
				text: null,
				value: null,
				operator: value.operator,
			})),
		};
	}

	return {
		kind: FILTER_KIND.INTERVAL,
		field: filter.field,
		values: filter.values.map((value) => ({
			text: JSON.stringify(value),
			value: null,
		})),
	};
};

const transformFilterForSave = (filters: Filter[]) =>
	filters.reduce<UpdateFilter[]>((acc, filter) => {
		// All filters must have a field except for text ones
		if (filter.type !== TEXT_FIELD_FILTER && !filter.field) {
			return acc;
		}

		let newFilter: UpdateFilter | undefined;
		switch (filter.type) {
			case GENERIC_FIELD_FILTER:
				newFilter = {
					kind: FILTER_KIND.FIELD_IDENTITY,
					field: filter.field,
					values: filter.values.map((fv) => ({
						text: fv.stringValue !== undefined ? fv.stringValue : null,
					})),
				};
				break;
			case NUMERIC_FIELD_FILTER:
				newFilter = {
					kind: FILTER_KIND.FIELD_NUMERIC,
					field: filter.field,
					values: filter.values.map((fv) => ({
						value: fv.numericValue !== undefined ? fv.numericValue : null,
						operator: fv.operator,
					})),
				};
				break;
			case INTERVAL_FIELD_FILTER:
				newFilter = transformIntervalFilter(filter);
				break;
			case TEXT_FIELD_FILTER:
				if (filter.localId !== 'quick') {
					newFilter = {
						kind: FILTER_KIND.TEXT,
						values: filter.values.map((fv) => ({
							text: fv.stringValue !== undefined ? fv.stringValue : null,
						})),
					};
				}
				break;
			default:
		}

		if (newFilter) {
			acc.push(newFilter);
		}

		return acc;
	}, []);

const transformDescriptionForSave = (description: View['description']) => description ?? null;

const transformSortForSave = (sort: SortField[] | undefined = []) =>
	sort
		.filter(({ fieldKey }) => fieldKey)
		.map<UpdateSort>(({ asc, fieldKey }) => ({
			order: asc ? 'ASC' : 'DESC',
			field: fieldKey,
		}));

const transformMatrixConfigForSave = (
	matrixConfig: PolarisMatrixConfig | undefined,
): UpdateMatrixConfig | undefined => {
	if (!matrixConfig?.axes) return undefined;

	return {
		axes: matrixConfig.axes
			.map(({ field, dimension, fieldOptions, reversed }) =>
				field?.key && dimension
					? {
							field: field.key,
							dimension,
							fieldOptions,
							reversed,
						}
					: null,
			)
			.filter(Boolean),
	};
};

const transformTimelineConfigForSave = (
	timelineConfig: PolarisTimelineConfig | undefined,
): UpdateTimelineConfig | undefined => {
	if (!timelineConfig) return undefined;
	return {
		startDateField: timelineConfig.startDateField?.key,
		dueDateField: timelineConfig.dueDateField?.key,
		mode: timelineConfig.mode,
		startTimestamp: timelineConfig.startTimestamp,
		endTimestamp: timelineConfig.endTimestamp,
		summaryCardField: timelineConfig.summaryCardField?.key,
	};
};

const transformTableColumnSizes = (
	tableColumnSizes: PolarisViewTableColumnSize[],
): UpdateTableColumnSize[] | undefined => {
	if (!tableColumnSizes) return undefined;
	return tableColumnSizes
		.filter(({ fieldKey }) => fieldKey)
		.map(({ fieldKey, size }) => ({
			field: fieldKey,
			size,
		}));
};

const transformFieldRollups = (fieldRollups: FieldRollup[]): UpdateFieldRollup[] | undefined => {
	if (!fieldRollups) return undefined;
	return fieldRollups
		.filter(({ fieldKey }) => fieldKey)
		.map(({ fieldKey, rollup }) => ({
			field: fieldKey,
			rollup,
		}));
};

const DEBOUNCE_DELAY = 600;
const debouncedApiCallMap: Record<string, Function> = {};
const debouncedSaveViewInternal = (
	dispatch: StoreActionApi<State>['dispatch'],
	id: string,
	onAfterSave?: (arg1: View | undefined) => void,
	shouldIgnoreAutosave = true,
) => {
	const key = `${id}:${shouldIgnoreAutosave}`;
	if (debouncedApiCallMap[key] === undefined) {
		debouncedApiCallMap[key] = debounce((fn: Function) => fn(), DEBOUNCE_DELAY);
	}

	// For each field option, we debounce the api call (but not the local store state update)
	debouncedApiCallMap[key](() => {
		dispatch(saveViewInternal(id, onAfterSave, shouldIgnoreAutosave));
	});
};

const saveViewInternal =
	(
		id: string,
		onAfterSave?: (arg1: View | undefined) => void,
		shouldIgnoreAutosave = true,
		recursiveSaves = 3,
	): Action<State, Props> =>
	async ({ getState, dispatch }, props) => {
		const state = getState();
		const { viewRemote, onViewUpdateFailed, fields } = props;

		const view = findView(state.viewSets, (v) => v.id === id);

		if (view && !view.saving && view.modified) {
			dispatch(beforeSave(view.id));
			let hadError = false;

			try {
				if (view.viewId === undefined) {
					const { viewId, uuid } = await viewRemote.createView({
						name: view.title,
						description: transformDescriptionForSave(view.description),
						fields: view.fields,
						hidden: view.hidden,
						groupBy: view.groupBy ?? null,
						verticalGroupBy: view.verticalGroupBy ?? null,
						groupValues: view.groupValues,
						verticalGroupValues: view.verticalGroupValues,
						hideEmptyGroups: view.hideEmptyGroups,
						hideEmptyColumns: view.hideEmptyColumns,
						fieldRollups: transformFieldRollups(view.fieldRollups),
						filter: transformFilterForSave(view.filter),
						sort: transformSortForSave(view.sortBy),
						sortMode: view.sortMode,
						tableColumnSizes: transformTableColumnSizes(view.tableColumnSizes),
						matrixConfig: transformMatrixConfigForSave(view.matrixConfig),
						timelineConfig: transformTimelineConfigForSave(view.timelineConfig),
						projectAri: view.viewSetId,
						kind: view.kind,
						enabledAutoSave: true,
						layoutType: view.layoutType,
					});

					dispatch(afterSave({ id, viewId, uuid, updatedAt: null, onAfterSave }));
				} else {
					const pinnedViewId = getPinnedViewId(state);
					const isCurrentViewPinned = view.id === pinnedViewId;
					const isAutosaveEnabled = isCurrentViewPinned || view.isAutosaveEnabled;

					if (!isAutosaveEnabled && !isCurrentViewPinned && !shouldIgnoreAutosave) {
						dispatch(afterSave({ id, onAfterSave, updatedAt: view.updatedAtTimestamp }));
						return;
					}

					try {
						experience.view.updateView.start();

						const {
							node: { updatedAt },
						} = await viewRemote.updateView({
							uuid: view.uuid,
							viewId: view.viewId,
							name: view.title,
							emoji: view.emoji,
							description: transformDescriptionForSave(view.description),
							fields: view.fields,
							hidden: view.hidden,
							groupBy: view.groupBy ?? null,
							verticalGroupBy: view.verticalGroupBy ?? null,
							groupValues: view.groupValues,
							verticalGroupValues: view.verticalGroupValues,
							hideEmptyGroups: view.hideEmptyGroups,
							hideEmptyColumns: view.hideEmptyColumns,
							fieldRollups: transformFieldRollups(view.fieldRollups),
							filter: transformFilterForSave(view.filter),
							sort: transformSortForSave(view.sortBy),
							sortMode: view.sortMode,
							tableColumnSizes: transformTableColumnSizes(view.tableColumnSizes),
							matrixConfig: transformMatrixConfigForSave(view.matrixConfig),
							timelineConfig: transformTimelineConfigForSave(view.timelineConfig),
							lastCommentsViewedTimestamp:
								view.lastCommentsViewedTimestamp !== undefined
									? new Date(view.lastCommentsViewedTimestamp).toISOString()
									: null,
							lastViewedTimestamp:
								view.lastViewed !== undefined
									? view.lastViewed.find((lv) => lv.isCurrentUser)?.timestamp.toISOString()
									: null,
							enabledAutoSave: isAutosaveEnabled,
							layoutType: view.layoutType,
						});

						dispatch(afterSave({ id, onAfterSave, updatedAt }));

						experience.view.updateView.success();
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						if (isPermissionError(error) || isClientFetchError(error)) {
							experience.view.updateView.abort(error);
						} else {
							experience.view.updateView.failure(error);
						}

						throw error;
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				logViewError('actions.save', Object.assign(error, { viewId: view.viewId }));

				hadError = true;

				dispatch(afterSave({ id, error, onAfterSave, updatedAt: view.updatedAtTimestamp }));
				onViewUpdateFailed(error);

				if (view.viewId && !(error instanceof ViewNotFoundError)) {
					viewRemote
						.fetchView(view.viewId, fields)
						.then((remoteView) => {
							const errorWithChangeData = new Error(
								createUpdateErrorMessageLogSafe(view, remoteView, props),
							);
							fireErrorAnalytics(
								createErrorAnalytics('polaris.error.viewUpdateFailed', errorWithChangeData),
							);
							logViewError('actions.save', errorWithChangeData);
						})
						.catch((fetchError) => {
							if (fetchError instanceof AccessNotPermittedError) {
								dispatch(deleteView(view.id, true));
							}

							logViewError('actions.save', Object.assign(fetchError, { viewId: view.viewId }));
						});
				} else {
					logViewError('actions.save', error);
				}

				if (error instanceof ViewNotFoundError && view.id) {
					dispatch(deleteView(view.id, true));
				}
			}

			// If an error occured on save wait until the next user action before attempting to save
			// again to prevent getting in a tight loop of errors.
			if (!hadError && recursiveSaves > 0) {
				// Recurse to see if there are more modifications to be saved
				dispatch(saveViewInternal(id, undefined, shouldIgnoreAutosave, recursiveSaves - 1));
			}
		}
	};

export const saveView =
	(id: string, onAfterSave?: (arg1: View | undefined) => void): Action<State, Props> =>
	async ({ dispatch }) => {
		debouncedSaveViewInternal(dispatch, id, onAfterSave, true);
	};

// action to save view that considers isAutosaveEnabled view property
export const saveViewWithAutoSave =
	(id: string, onAfterSave?: (arg1: View | undefined) => void): Action<State, Props> =>
	async ({ dispatch }) => {
		debouncedSaveViewInternal(dispatch, id, onAfterSave, false);
	};
