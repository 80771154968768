import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DecoratedTag } from '../../../../../common/decoration/decoration/tag/index.tsx';
import messages from './messages';

type Props = {
	field: Field;
	groupIdentity: string | undefined;
};

export const labelNoValueOptionMessage = messages.noValueOption;

export const LabelOptionRenderer = ({ field, groupIdentity }: Props) => {
	const { formatMessage } = useIntl();
	if (groupIdentity === undefined) {
		return <Box paddingInlineStart="space.100">{formatMessage(labelNoValueOptionMessage)}</Box>;
	}
	return <DecoratedTag fieldKey={field.key} id={groupIdentity} value={groupIdentity} />;
};
